import React from "react";
import styles from "properties/gallery/_css/propertyGallery.module.css";
import { ItemGalleryIcons } from "units/gallery/ItemGalleryIcons";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { Swiper, SwiperSlide } from "swiper/react";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/swiper-bundle.min.css";
import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { VideoComponent } from "components/base/video/VideoComponent";
import { Tour3D } from "components/base/tour3D/Tour3D";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { useUnitStore } from "units/UnitContext";

type Props = {
    indexGallery: number;
    setIndexGallery: (number: number) => void;
    resource: MAIN_RESOURCE;
    lengthImgAndVideo: number;
    indexForVideoOrVisit3D: number[];
    thumbsSwiper: any;
};

export function PropertyOrUnitGallerySwiper({
    indexGallery,
    setIndexGallery,
    resource,
    lengthImgAndVideo,
    indexForVideoOrVisit3D,
    thumbsSwiper,
}: Props) {
    const propertyStore = usePropertyStore();
    const unitStore = useUnitStore();
    const item = resource === MAIN_RESOURCE.PROPERTIES ? propertyStore.property : unitStore.unit;
    const store = resource === MAIN_RESOURCE.PROPERTIES ? propertyStore : unitStore;
    const isLand = propertyStore.property.type === PROPERTY_TYPE.land;
    return (
        <Swiper
            navigation
            loop
            initialSlide={0}
            slidesPerView={1}
            thumbs={{ swiper: thumbsSwiper }}
            onSlideChange={(swiper) => {
                setIndexGallery(
                    swiper.activeIndex <= lengthImgAndVideo
                        ? swiper.activeIndex === 0
                            ? lengthImgAndVideo
                            : swiper.activeIndex
                        : 1,
                );
            }}
            className={styles.sliderMain}
        >
            <ItemGalleryIcons
                resource={resource}
                indexGallery={indexGallery}
                indexForVideoOrVisit3D={indexForVideoOrVisit3D}
                lenghtImgAndVideo={lengthImgAndVideo}
            />
            {item.photos.map((image, index) => {
                return (
                    <SwiperSlide
                        onClick={() => {
                            store.openGallery(index);
                        }}
                        key={index + "thumbs"}
                        className={styles.swiperContainer}
                    >
                        {index === 0 ? (
                            <img
                                alt={`${item.localized.title}-${index}`}
                                title={`${item.localized.title} photo ${index}`}
                                className={clsx(styles.mainPhoto, { [styles.mainFullHeight]: isLand })}
                                src={image.url}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "https://placehold.co/250?text=Vistoo.com";
                                }}
                            />
                        ) : (
                            <LazyLoadImage
                                alt={`${item.localized.title}-${index}`}
                                title={`${item.localized.title} photo ${index}`}
                                effect={"blur"}
                                className={clsx(styles.mainPhoto, { [styles.mainFullHeight]: isLand })}
                                src={image.url}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "https://placehold.co/250?text=Vistoo.com";
                                }}
                            />
                        )}
                    </SwiperSlide>
                );
            })}
            {item.video && (
                <SwiperSlide className={clsx(styles.slideVideo, { [styles.mainFullHeight]: isLand })}>
                    <VideoComponent size={"100%"} url={item.video} />
                </SwiperSlide>
            )}
            {item.video2 && (
                <SwiperSlide className={clsx(styles.slideVideo, { [styles.mainFullHeight]: isLand })}>
                    <VideoComponent size={"100%"} url={item.video2} />
                </SwiperSlide>
            )}
            {item.visit3D && (
                <SwiperSlide className={clsx(styles.slideVideo, { [styles.mainFullHeight]: isLand })}>
                    <Tour3D embedSrc={item.visit3D} />
                </SwiperSlide>
            )}
        </Swiper>
    );
}
