import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import clsx from "clsx";
import styles from "properties/propertyPage/_css/propertyUnitsCard.module.css";
import { TUnitListingMdl, UNIT_EVENT } from "units/_models/UnitMdl";
import { observer } from "mobx-react";
import { URLS } from "_configs/URLS";
import { ToggleToFavoriteBtn } from "properties/ToggleToFavoriteBtn";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { CompareBtnUnitCard } from "compare/CompareBtnUnitCard";
import bedrooms from "../../assets/images/bedrooms.svg";
import bathrooms from "../../assets/images/bathrooms.svg";
import { formatPrice } from "_common/_utils/currencyUtils";
import { Link } from "react-router-dom";
import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";
import { getWebpFile } from "_common/_utils/fileUtils";
import { useOnScreen } from "_common/_utils/useOnScreen";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { useLocation } from "react-router";

type Props = {
    unit: TUnitListingMdl;
    notDisplayPrice: boolean;
};

export const HorizontalUnitCard = observer(({ unit, notDisplayPrice }: Props) => {
    const { t } = useTranslation();
    const propertyStore = usePropertyStore();
    const ref = useRef<HTMLAnchorElement>(null);
    const url = useLocation().pathname;
    const { isIntersecting } = useOnScreen(ref, 0.5);

    useEffect(() => {
        if (!unit || !unit.localized.title) return;
        if (isIntersecting) {
            void analyticsUtils.tracksEventOfItem(UNIT_EVENT.PRINTED, unit._id, url);
        }
    }, [isIntersecting]);

    return (
        <Link
            ref={ref}
            className={clsx(styles.link, "flex_row_center mt_15")}
            to={URLS.unit(propertyStore.property.localized.urlAlias, unit.localized.urlAlias)}
        >
            <img
                src={getWebpFile(unit.photos[0]?.thumbnail) ?? unit.photos[0]?.url}
                alt={unit.photos[0]?.alt ?? `Photo ${unit.localized.title}`}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "https://placehold.co/100x100?text=Vistoo.com";
                }}
                className={clsx("flex_column", styles.img)}
            />
            <div className={clsx("flex_row flex-1 p_10 ph_20", styles.contentCard)}>
                <div className={"flex_column justifyContent_spaceAround flex-1"}>
                    <div className={clsx("flex_row_center", styles.firstRow)}>
                        <div className={styles.titleUnit}>{unit.localized.title}</div>
                        <div className={clsx(styles.tilde, "mh_20")}>|</div>
                        <div className={"flex_row"}>
                            <img src={bedrooms} alt={"bedrooms"} className={clsx(styles.icon, "mr_16")} />
                            <div className={clsx("mr_16", styles.surface)}>{unit.bedrooms}</div>
                            <img src={bathrooms} alt={"bathrooms"} className={clsx(styles.icon, "mr_16")} />
                            <div className={clsx("mr_16", styles.surface)}>{unit.bathrooms}</div>
                        </div>
                    </div>
                    <div className={clsx("flex_row", styles.rowSurface)}>
                        {!!unit.squareSurface?.min && (
                            <div className={clsx("flex_row_center mr_40 text_noWrap")}>
                                <div className={styles.surface}>
                                    {t("unit.squareSurface.surfaceMinOnly", {
                                        min: unit.squareSurface?.min,
                                    })}
                                    {` (${convert(unit.squareSurface.min, UNIT_CONVERSION.FT2_TO_M2)}m²)`}
                                </div>
                            </div>
                        )}
                        <div className={clsx("flex_row text_noWrap", styles.rowPrice)}>
                            {notDisplayPrice ? (
                                <div className={styles.notDisplayedPrice}>
                                    {t("propertiesPage.card.priceOnRequest")}
                                </div>
                            ) : (
                                <div className={clsx("flex_row", styles.price)}>
                                    {t("propertiesPage.card.priceMinOnly", {
                                        min: formatPrice(unit.price.min, false),
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={"flex_column alignItems_center justifyContent_spaceAround"}>
                    <ToggleToFavoriteBtn item={unit} type={MAIN_RESOURCE.UNITS} colors={"primary"} />
                    <CompareBtnUnitCard id={unit._id} type={MAIN_RESOURCE.UNITS} />
                </div>
            </div>
        </Link>
    );
});
