import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { GROUP_FEATURES, PROPERTY_FEATURES_AMENITIES, TOptionConfigDef } from "_configs/propertyConfig";
import styles from "./_css/advanced.module.css";
import { observer } from "mobx-react";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardArrowDown } from "@material-ui/icons";
import { TextField } from "_common/ui/forms/TextField";
import { Checkbox } from "@material-ui/core";
import { CollapseSection } from "_common/ui/components/CollapseSection";

type Props = {
    onClose?: () => void;
    onChange: (features: string[]) => void;
    value: string[];
};
export const AdvancedFilter = observer((props: Props) => {
    const { t } = useTranslation();
    const propertiesStore = usePropertiesStore();

    const [value, setValue] = useState("");
    const [open, setOpen] = useState(false);
    let groupDisplayed: GROUP_FEATURES[] = [];
    const selectFilter = (option: TOptionConfigDef, isGroup?: boolean) => {
        const ids =
            option.group && isGroup
                ? PROPERTY_FEATURES_AMENITIES.filter((feature) => feature.group === option.group).map(
                      (f) => `${f.optionType}.${f.key}`,
                  )
                : [`${option.optionType}.${option.key}`];
        props.onChange(ids);
    };

    return (
        <div className={clsx(styles.container, "ph_15 pv_10 position_relative")}>
            <CollapseSection heightOfCollapsable={0} title={t("searchBar.filter.features")}>
                <>
                    <div>
                        {PROPERTY_FEATURES_AMENITIES.sort((a, b) =>
                            t(`property.features.${a.key}`) > t(`property.features.${b.key}`) ? 1 : -1,
                        )
                            .filter(
                                (option) =>
                                    option.purpose &&
                                    option.purpose.find((purpose) => purpose === propertiesStore.purpose),
                            )
                            .map((option) => {
                                if (option.group && !groupDisplayed.includes(option.group)) {
                                    groupDisplayed = [...groupDisplayed, option.group];
                                    return (
                                        <div
                                            key={option.label}
                                            className={"flex_row_center cursor_pointer"}
                                            onClick={() => selectFilter(option, true)}
                                        >
                                            <Checkbox
                                                className={styles.check}
                                                checked={PROPERTY_FEATURES_AMENITIES.filter(
                                                    (feature) => feature.group === option.group,
                                                ).some((featureInOption) =>
                                                    props.value.includes(
                                                        `${featureInOption.optionType}.${featureInOption.key}`,
                                                    ),
                                                )}
                                            />
                                            <div>{t(`property.features.${option.group}`)}</div>
                                        </div>
                                    );
                                } else if (!option.group) {
                                    return (
                                        <div
                                            key={option.label}
                                            className={"flex_row_center cursor_pointer"}
                                            onClick={() => selectFilter(option)}
                                        >
                                            <Checkbox
                                                className={styles.check}
                                                checked={props.value.includes(`${option.optionType}.${option.key}`)}
                                            />
                                            <div>{t(`property.features.${option.key}`)}</div>
                                        </div>
                                    );
                                }
                            })}
                    </div>
                    <div className={"mt_20"}>
                        <Autocomplete<TOptionConfigDef>
                            open={open}
                            onOpen={(event) => {
                                if (event.type !== "mousedown") {
                                    setOpen(true);
                                }
                            }}
                            onClose={() => setOpen(false)}
                            options={PROPERTY_FEATURES_AMENITIES}
                            getOptionLabel={(option) => t(`property.features.${option.key}`)}
                            getOptionDisabled={(option) => props.value.includes(`${option.optionType}.${option.key}`)}
                            inputValue={value}
                            onInputChange={(_event, newInputValue, _reason) => {
                                if (_reason === "reset") return;
                                const options = PROPERTY_FEATURES_AMENITIES.find(
                                    (option) => t(`property.features.${option.key}`) == newInputValue,
                                );
                                if (options) {
                                    selectFilter(options);
                                    setValue("");
                                    setOpen(false);
                                } else {
                                    setValue(newInputValue);
                                }
                            }}
                            renderInput={(params) => {
                                return (
                                    <TextField {...params} variant="outlined" label={t(`searchBar.filter.features`)} />
                                );
                            }}
                            onChange={(_e, _value) => {
                                if (_value) {
                                    selectFilter(_value);
                                    setOpen(false);
                                    if (value) setValue("");
                                }
                            }}
                            popupIcon={
                                <KeyboardArrowDown
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setOpen(!open);
                                    }}
                                />
                            }
                        />
                        <div className={"flex_row_center flexWrap_wrap mt_10 gap_10"}>
                            {PROPERTY_FEATURES_AMENITIES.filter((filter) =>
                                props.value.includes(`${filter.optionType}.${filter.key}`),
                            ).map((filter) => {
                                return (
                                    <div
                                        key={filter.key}
                                        className={clsx(styles.tag)}
                                        onClick={() => {
                                            selectFilter(filter);
                                        }}
                                    >
                                        {t(`property.features.${filter.key}`)} x
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </>
            </CollapseSection>
        </div>
    );
});
