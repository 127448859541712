import React from "react";
import styles from "./_css/propertyFeatureCardSrps.module.css";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { getWebpFile } from "_common/_utils/fileUtils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";

type Props = {
    property: TPropertyListingMdl;
};

export function PropertyCardSrps({ property }: Props) {
    return (
        <Link to={URLS.property(property.localized.urlAlias)} className={styles.container}>
            <LazyLoadImage
                src={getWebpFile(property.photos?.[0]?.thumbnail) ?? property.photos?.[0]?.url}
                className={styles.img}
                alt={"property image"}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "https://placehold.co/300?text=Vistoo.com";
                }}
            />
            <div className={styles.content}>
                <div className={"truncate"}>{property.localized.title}</div>
                <div>{property.address.city}</div>
            </div>
        </Link>
    );
}
