import React from "react";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import styles from "home/sections/_css/showcaseProperties.module.css";
import clsx from "clsx";
import { Helmet } from "react-helmet-async";
import "swiper/swiper-bundle.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import SwiperCore, { Autoplay, Pagination } from "swiper";

SwiperCore.use([Pagination, Autoplay]);

export default function ShowcasePropertiesFallback() {
    return (
        <>
            <Helmet>
                <link
                    rel="preload"
                    fetchpriority="high"
                    as="image"
                    href={propertiesStore.showcaseProperties?.[0]?.photos?.[0]?.url}
                    sizes={"100vw"}
                    aria-label={propertiesStore.showcaseProperties?.[0]?.localized?.title}
                    aria-labelledby={propertiesStore.showcaseProperties?.[0]?.localized?.title}
                />
            </Helmet>
            <Helmet>
                <link
                    rel="preload"
                    fetchpriority="high"
                    as="image"
                    href={propertiesStore.showcaseProperties?.[1]?.photos?.[1]?.url}
                    sizes={"100vw"}
                    aria-label={propertiesStore.showcaseProperties?.[0]?.localized?.title}
                    aria-labelledby={propertiesStore.showcaseProperties?.[0]?.localized?.title}
                />
            </Helmet>
            <Link
                to={URLS.property(propertiesStore.showcaseProperties?.[0]?.localized?.urlAlias)}
                aria-label={propertiesStore.showcaseProperties?.[0]?.localized?.title}
                aria-labelledby={propertiesStore.showcaseProperties?.[0]?.localized?.title}
            >
                <div className="position_relative">
                    <div className={styles.overlay} />
                    <div className={clsx(styles.place, "flex_row position_absolute p_20 pv_5")}>
                        <div>
                            <div className={styles.pinTitle}>
                                {propertiesStore.showcaseProperties?.[0]?.localized?.title}
                            </div>
                            <div className={styles.cityRegion}>
                                {propertiesStore.showcaseProperties?.[0]?.propertyBuilderName ??
                                    propertiesStore.showcaseProperties?.[0]?.developer?.companyName ??
                                    ""}
                            </div>
                        </div>
                    </div>
                    {propertiesStore.showcaseProperties?.[0]?.photos?.[0] && (
                        <img
                            fetchpriority="high"
                            src={propertiesStore.showcaseProperties?.[0]?.photos[0]?.url}
                            alt={propertiesStore.showcaseProperties?.[0]?.photos[0]?.url}
                            className={styles.img}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "https://placehold.co/1920x1080?text=Vistoo.com";
                            }}
                        />
                    )}
                </div>
            </Link>
        </>
    );
}
