import React, { useEffect, useRef, useState } from "react";
import { TUnitListingMdl, UNIT_EVENT } from "units/_models/UnitMdl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "./_css/unitCard.module.css";
import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { IconComponent } from "components/base/icon/IconComponent";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { formatPrice } from "_common/_utils/currencyUtils";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { ToggleToFavoriteBtn } from "properties/ToggleToFavoriteBtn";
import { CompareBtn } from "shared/compare/CompareBtn";
import { PROPERTY_PURPOSE, PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { EditCardBtn } from "users/dashboard/edit/EditCardBtn";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { useOnScreen } from "_common/_utils/useOnScreen";
import { useLocation } from "react-router";

SwiperCore.use([Navigation]);

type Props = {
    unit: TUnitListingMdl;
    isEditable?: boolean;
    onEdit?: () => void;
    isDashboardColumn?: boolean;
    newTab?: boolean;
};

export function _UnitCard(props: Props) {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const url = useLocation().pathname;
    const { isIntersecting } = useOnScreen(ref, 0.5);

    useEffect(() => {
        if (props.isEditable) return;
        if (!props.unit || !props.unit.localized.title) return;
        if (isIntersecting) {
            void analyticsUtils.tracksEventOfItem(UNIT_EVENT.PRINTED, props.unit._id, url);
        }
    }, [isIntersecting]);

    if (!props.unit || !props.unit.localized.title) return null;

    return (
        <>
            <div id="unitCard" ref={ref} className={clsx(styles.card, "flex_column")}>
                <div className="position_relative">
                    <CompareBtn id={props.unit._id} type={MAIN_RESOURCE.UNITS} />
                    <ToggleToFavoriteBtn item={props.unit} type={MAIN_RESOURCE.UNITS} />
                    {props.unit.sold && (
                        <div className={styles.tag}>
                            {props.unit.property.purpose === PROPERTY_PURPOSE.BUY
                                ? props.unit.property.type === PROPERTY_TYPE.condo
                                    ? t("property.soldOutCondo")
                                    : t("property.soldOut")
                                : t("property.rented")}
                        </div>
                    )}
                    <Swiper navigation slidesPerView={1} loop observer observeParents>
                        {props.unit.photos.map((image, index) => {
                            return (
                                <SwiperSlide key={index} className={styles.swiperContainer}>
                                    <Link
                                        to={
                                            props.unit.property.localized?.urlAlias
                                                ? URLS.unit(
                                                      props.unit.property.localized.urlAlias,
                                                      props.unit.localized.urlAlias,
                                                  )
                                                : ""
                                        }
                                        onClick={(e) => {
                                            if (props.newTab && props.unit.property.localized?.urlAlias) {
                                                e.preventDefault();
                                                const win = window.open(
                                                    URLS.unit(
                                                        props.unit.property.localized.urlAlias,
                                                        props.unit.localized.urlAlias,
                                                    ),
                                                    "_blank",
                                                );
                                                if (win) win.focus();
                                            }
                                        }}
                                    >
                                        <LazyLoadImage
                                            alt={`${props.unit.localized.title}-1`}
                                            title={`${props.unit.localized.title} photo 1`}
                                            effect={"blur"}
                                            className={styles.image}
                                            src={image.thumbnail ? image.thumbnail : image.url}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = "https://placehold.co/300?text=Vistoo.com";
                                            }}
                                        />
                                    </Link>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
                <Link
                    to={
                        props.unit.property.localized?.urlAlias
                            ? URLS.unit(props.unit.property.localized.urlAlias, props.unit.localized.urlAlias)
                            : ""
                    }
                    onClick={(e) => {
                        if (props.newTab && props.unit.property.localized?.urlAlias) {
                            e.preventDefault();
                            const win = window.open(
                                URLS.unit(props.unit.property.localized.urlAlias, props.unit.localized.urlAlias),
                                "_blank",
                            );
                            if (win) win.focus();
                        }
                    }}
                    className="pb_10 ph_10"
                >
                    <h4 className={clsx(styles.title, "ml_10 mt_10 fontWeight_bold")}>{props.unit.localized.title}</h4>
                    <Grid container className={"mt_30 ml_10 mb_20"}>
                        <Grid item xs={6} className={"flex_row"}>
                            <IconComponent icon="king_bed" />
                            <div className={"ml_10"}>{t(`unit.bedroom`, { count: props.unit.bedrooms })}</div>
                        </Grid>
                        {props.unit.squareSurface &&
                            !!props.unit.squareSurface?.min &&
                            props.unit.squareSurface?.min !== 0 && (
                                <Grid item xs={6} className={"flex_row pl_20"}>
                                    <IconComponent icon="square_foot" />
                                    <div className={"ml_10"}>
                                        {props.unit.squareSurface?.min
                                            ? `${props.unit.squareSurface?.min} ${t("words.ft2")}`
                                            : t("propertiesPage.card.surfaceOnRequest")}
                                    </div>
                                </Grid>
                            )}
                        <Grid item xs={6} className={"flex_row"}>
                            <IconComponent icon="bathtub" />
                            <div className={"ml_10"}>{t(`unit.bathroom`, { count: props.unit.bathrooms })}</div>
                        </Grid>
                        {props.unit.squareSurface &&
                            !!props.unit.squareSurface?.min &&
                            props.unit.squareSurface?.min !== 0 && (
                                <Grid item xs={6} className={"flex_row pl_20"}>
                                    <IconComponent icon="square_foot" />
                                    <div className={"ml_10"}>
                                        {props.unit.squareSurface?.min
                                            ? `${convert(props.unit.squareSurface.min, UNIT_CONVERSION.FT2_TO_M2)} ${t(
                                                  "words.m2",
                                              )}`
                                            : t("propertiesPage.card.surfaceOnRequest")}
                                    </div>
                                </Grid>
                            )}
                    </Grid>
                </Link>
            </div>
            {props.unit.priceIsNotDisplayed || !props.unit.price?.min || props.unit.price.min === 0 ? (
                <div className={"flex_rowReverse mt_10"}>
                    <div
                        className={clsx(styles.price, "fontWeight_bold mt_8 mb_5", {
                            [styles.priceForDashboard]: props.isDashboardColumn,
                        })}
                    >
                        {t("propertiesPage.card.priceOnRequest")}
                    </div>
                </div>
            ) : (
                <div className={"flex_rowReverse mt_10"}>
                    <div
                        className={clsx(styles.price, "flex_row mr_10 mt_5", {
                            [styles.priceForDashboard]: props.isDashboardColumn,
                        })}
                    >
                        {props.unit.price?.min ? formatPrice(props.unit.price.min, false) : 0}
                        {props.unit.property.purpose === PROPERTY_PURPOSE.RENT ? (
                            t("propertiesPage.card.perMonth")
                        ) : (
                            <span className={"text_discreet ml_5"}>
                                {" "}
                                {!props.unit.property?.taxIsIncluded ? t("property.taxes") : ""}
                            </span>
                        )}
                    </div>
                    <div
                        className={clsx(styles.from, "flex_row mr_10", {
                            [styles.fromForDashboard]: props.isDashboardColumn,
                        })}
                    >
                        {t("propertyPage.from")}
                    </div>
                </div>
            )}
        </>
    );
}

export const UnitCard = (props: Props) => {
    const [isOverCard, setIsOverCard] = useState(false);
    if (props.isEditable) {
        return (
            <div
                className={"position_relative"}
                onMouseEnter={() => setIsOverCard(true)}
                onMouseLeave={() => setIsOverCard(false)}
            >
                <EditCardBtn
                    isOverCard={isOverCard}
                    link={URLS.unit(props.unit.property.localized.urlAlias, props.unit.localized.urlAlias)}
                    onEdit={props.onEdit}
                    onClose={() => setIsOverCard(false)}
                />
                <_UnitCard {...props} />
            </div>
        );
    } else return <_UnitCard {...props} />;
};
